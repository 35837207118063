import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import AuthenticationService from '../services/authentication-service';
import SquamaNavbar from '../components/squama-navbar';

type ModalItems = {
  username: string;
  password: string;
  longLogin: boolean;
}
const Login: FunctionComponent = () => {
  const displayName = "Login:";
  const enableDebug = false;
  const enableMoreDebug = false;

  const history = useNavigate();
  const { register, handleSubmit, formState: {errors}, reset } = useForm<ModalItems>();

  const [displayPassword, setDisplayPassword] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('Entrez vos identifiants:');
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if(enableDebug)console.log(displayName,"useEffect");
    if (AuthenticationService.getAuthenticated()) {
      let from = location.state?.from?.pathname || "/dashboard";
      if(enableMoreDebug)console.log(displayName,"$$$$$$login Now authenticated ! routing to ",from);
      navigate(from, { replace: true });
    }
  },[location]);
  const handleDemoSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMessage('👉 Connection Démo en cours ...');
    AuthenticationService.login("squama@squama.fr", "squama", false).then(isAuthenticated => {
      if(!isAuthenticated) {
        if(enableDebug)console.log(displayName,"Unable to login");
        setMessage('🔐 Impossible de se connecter à la session Démo.');
        return;
      }
      history('/dashboard');
    });
  }
  const onHookFormSubmit = (data: ModalItems) => {
    if(enableDebug)console.log(displayName,"data", data);
    setMessage('👉 Tentative de connexion en cours ...');
    AuthenticationService.login(data.username, data.password, data.longLogin).then(isAuthenticated => {
      if(!isAuthenticated) {
        setMessage('🔐 Identifiant ou mot de passe incorrect.');
        return;
      }
      history('/dashboard');
    });
  }

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <form className="main-center-div the-background" onSubmit={handleSubmit(onHookFormSubmit)}>
            <div className="card login-card login-part">
              <div className="card-body">
                    {message && <h5 className="card-title">{message}</h5>}
                    <div className="form-group">
                      <label htmlFor="username"><b>Identifiant : </b></label>
                      <input id="username" type="text" className="form-control" {...register("username",{ required: true, pattern: /^\S+@\S+$/i })}></input>
                      {errors.username && <div className="card-text red-font accent-1">Mauvais format d'email</div>}
                    </div>
                    <div className="form-group">
                    <Link to="/forgotlogin">Mot de passe oublié ?</Link>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password"><b>Mot de passe : </b></label>
                      <input id="password" type={(displayPassword)?"text":"password"} className="form-control" {...register("password",{ required: true, minLength: 6 })}></input>
                      {errors.password && <div className="card-text red-font accent-1">Au moins 6 caractères</div>}
                    </div>
                    <div className="form-group login-stay-connected">
                      <input id="displayPassword" type="checkbox" name="displayPassword" defaultChecked={displayPassword} onChange={()=>setDisplayPassword(!displayPassword)} />
                      <div>&nbsp;&nbsp;Afficher le mot de passe</div>
                    </div>
                    <div className="form-group login-stay-connected">
                      <input id="longLogin" type="checkbox" {...register("longLogin",{ required: false })} />
                      <div>&nbsp;&nbsp;Se souvenir de moi</div>
                    </div>
                    <div className="panel-station-footer">
                      <button type="submit" className="btn-station-card btn-login">Valider</button>
                      <button className="btn-station-card btn-login" onClick={(e) => {reset();handleDemoSubmit(e);}}>Démo</button>
                    </div>
              </div>
          </div>
      </form>
    </div>
  );
};
 
export default Login;