import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {isCurrentUserGod} from '../helpers/auth-helper';
import { return_logo_from_type } from '../helpers/station-helper';

type NavbarProps = {
  thepath: string
}

const SquamaNavBar: FunctionComponent<NavbarProps> = ({thepath}) => {
  const [showMap, setshowMap] = useState(true);
  const [showUnMap, setshowUnMap] = useState(true);

  useEffect( ()=>{
    if (thepath.includes("/map")){
      setshowUnMap(true);
      setshowMap(false);
    }else if (
      thepath.includes("/companies/")){
      setshowUnMap(false);
      setshowMap(true);
    }else{
      setshowUnMap(false);
      setshowMap(false);
    }
  },[thepath]);

  function not_connected_dashboard(){
    return (
      <div className="row-navbar">
        <div className="squama-item-navbar"><Link to='/accueil'><button type="button" className="squama-btn-navbar">
            Accueil
        </button></Link></div>
        <div className="squama-item-navbar"><Link to='/solutions'><button type="button" className="squama-btn-navbar">
            Solutions
        </button></Link></div>
        <div className="squama-item-navbar"><Link to='/apropos'><button type="button" className="squama-btn-navbar">
            À propos
        </button></Link></div>
        <div className="squama-item-navbar"><Link to='/contact'><button type="button" className="squama-btn-navbar">
            Contact
        </button></Link></div>
        <div className="squama-item-navbar"><Link to='/login'><button type="button" className="squama-btn-navbar">
            Accès/Démo
        </button></Link></div>
      </div>
    );
  }

  function connected_dashboard(thepath:string){
    return (
      <div>
        <div className="row-navbar">
          <div className="row-navbar">
            {
              (current_user_is_god) && (showMap) && (
                <Link to={thepath+'/map'}>{return_logo_from_type("dashboard-selected_map-greyed", "logo-oblon-navbar-png")}</Link>
              )
            }
            {
              (current_user_is_god) && (showUnMap) && (
                <Link to={thepath.replace('/map','')}>{return_logo_from_type("dashboard-greyed_map-selected", "logo-oblon-navbar-png")}</Link>
              )
            }
          </div>
          <div className="row-navbar">
            <div className="squama-item-navbar"><Link to='/dashboard'><button type="button" className="squama-btn-navbar">
                Tableau de bord
            </button></Link></div>
            <div className="squama-item-navbar"><Link to='/parameters'><button type="button" className="squama-btn-navbar">
                Paramètres
            </button></Link></div>
            <div className="squama-item-navbar"><Link to='/contactconnected'><button type="button" className="squama-btn-navbar">
                Contact
            </button></Link></div>
            {
              (current_user_is_god) && (
                <div className="squama-item-navbar"><Link to='/gestion'><button type="button" className="squama-btn-navbar">
                Gestion
                </button></Link></div>
              )
            }
          </div>
          <div className="row-navbar">
            <Link to='/logout'>
              {return_logo_from_type("logout-oblon", "logo-oblon-navbar-png")}
            </Link>
          </div>
        </div>
      </div>
      );
  }
  let current_user_is_god = isCurrentUserGod();
  //console.log("current_user_is_god:"+current_user_is_god);
  //console.log("thepath="+thepath);

  if (
      (thepath==="/dashboard") ||
      (thepath==="/parameters") ||
      (thepath==="/contactconnected") ||
      (thepath==="/gestion") ||
      (thepath==="/map") ||
      (thepath.includes("/stations/")) ||
      (thepath.includes("/companies/"))
    )
    {
    return connected_dashboard(thepath);
  }else{
    return not_connected_dashboard();
  }

}
  
export default SquamaNavBar;