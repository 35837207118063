export default class Contact {
    // 1. Typage des propiétés d'une sonde de niveau d'eau.
    user_id: number;
    id: number;
    station_id: number;
    company_id: number;
    email: string;
    alarm: number;
    warning: number;
    role: string;
    name: string;
    phone: string;
    hashed_password: string;
    crypted_data: string;
    alimentation_warning: boolean;
    alimentation_alarm: boolean;
    embacle_warning: boolean;
    embacle_alarm: boolean;
    setup_access: boolean;
    alarm_access: boolean;
    display_access: boolean;
    download_access: boolean;
    other_access: boolean;
    station_order: string;
    alarm_pkd: boolean;
    admin: boolean;
     
    // 2. Définition des valeurs par défaut des propriétés d'une sonde de niveau d'eau.
    constructor(
     user_id: number,
     id: number=0,
     station_id: number = 0,
     company_id: number = 0,
     email: string = "",
     alarm: number = 0,
     warning: number = 0,
     role: string = "user",
     name: string = "",
     phone: string = "",
     hashed_password: string = "",
     crypted_data: string = "",
     alimentation_warning: boolean = false,
     alimentation_alarm: boolean = false,
     embacle_warning: boolean = false,
     embacle_alarm: boolean = false,
     setup_access: boolean = false,
     alarm_access: boolean = false,
     display_access: boolean = true,
     download_access: boolean = true,
     other_access: boolean = false,
     station_order: string = "[]",
     alarm_pkd: boolean = false,
     admin: boolean = false,

    ) {
     // 3. Initialisation des propriétés d'une sonde de niveau d'eau.
     this.user_id = user_id;
     this.id = id;
     this.station_id = station_id;
     this.company_id = company_id;
     this.email = email;
     this.alarm = alarm;
     this.warning = warning;
     this.role = role;
     this.name = name;
     this.phone = phone;
     this.hashed_password = hashed_password;
     this.crypted_data = crypted_data;
     this.alimentation_warning = alimentation_warning;
     this.alimentation_alarm = alimentation_alarm;
     this.embacle_warning = embacle_warning;
     this.embacle_alarm = embacle_alarm;
     this.setup_access = setup_access;
     this.alarm_access = alarm_access;
     this.display_access = display_access;
     this.download_access = download_access;
     this.other_access = other_access;
     this.station_order = station_order;
     this.alarm_pkd = alarm_pkd;
     this.admin = admin;
    }
}