import AuthenticationService from '../services/authentication-service';

export function isCurrentUserGod(){
    //console.log('UserStatus:'+AuthenticationService.getUserStatus())
    if (AuthenticationService.getUserIsGod()){
        //console.log("!!! Current user is god !!!");
        return true;
    }
    return false;
}

export function isLoggedIn(){
    if (AuthenticationService.getUserID()!==undefined){
        return true;
    }
    return false;
}
