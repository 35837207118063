import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import Bcrypt from "react-native-bcrypt";

import Company from '../../models/company';
import Contact from '../../models/contact';

import StationService from '../../services/station-service';
import CompanyService from '../../services/company-service';
import AuthenticationService from '../../services/authentication-service';

import { io } from "socket.io-client";
import {authHeader} from '../../helpers/auth-headers';
import SiteService from '../../services/site-service';
import { return_logo_from_type } from '../../helpers/station-helper';

type ModalItems = {
  companyIdSelected: number;
  userIdSelected: number;
  allSquamaUserIdSelected: number;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  contactRole: string;
  contactNotHashedPasswordYet: string;
  contactNotHashedConfirmedPasswordYet: string;
}

const ModalCreateModifyUser: FunctionComponent = () => {
  const displayName = "ModalCreateModifyUser:";
  const enableDebug = false;
  const showPassword = true;

  const { register, handleSubmit } = useForm<ModalItems>();

  const [companies, setCompanies] = useState<Company[]|null>(null);
  const [contacts, setContacts] = useState<Contact[]|null>(null);
  const [allStationId, setAllStationId] = useState<number[]|null>(null);
  const [askAllContacts, setAskAllContacts] = useState<boolean>(false);
  const [allSquamaContacts, setAllSquamaContacts] = useState<Contact[]|null>(null);
  const [selectedUserID, setSelectedUserId] = useState<number>(0);
  const [selectedAllSquamaUserID, setSelectedAllSquamaUserId] = useState<number>(0);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [usersRetrieved, setUsersRetrieved] = useState<boolean>(false);
  const [generatePassword, setGeneratePassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [contactName, setContactName] = useState<string>("");
  const [contactEmail, setContactEmail] = useState<string>("");
  const [contactPhone, setContactPhone] = useState<string>("");
  const [contactRole, setContactRole] = useState<string>("");
  const [contactNotHashedPasswordYet, setContactNotHashedPasswordYet] = useState<string>("");
  const [contactNotHashedConfirmedPasswordYet, setContactNotHashedConfirmedPasswordYet] = useState<string>("");

  const [showModal, setshowModal] = useState<boolean>(false);

  useEffect(() => {
    if(enableDebug) console.log(displayName,"useEffect");
    if(!showModal){return;}
    const socket = io(SiteService.getWebsocketUrl(), {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName, "logged_out:", logged_out_desc_str);
    });
    socket.on("answer", (answer: any) => {
      if (enableDebug) console.log(displayName,"Answer:", answer);
      if (answer.type === "station_id") {
        setAllStationId(answer.liste);
      }
    });
    socket.on("contacts_desc", (the_desc_str: string) => {
      const the_contacts = JSON.parse(the_desc_str);
      if (enableDebug) console.log(displayName, "the_contacts:", the_contacts);
      if(askAllContacts){
        setAllSquamaContacts(the_contacts);
      }else{
        setContacts(the_contacts);
        setUsersRetrieved(true);
      }
    });
    socket.on("companies_desc", (the_desc_str: string) => {
      const the_companies = JSON.parse(the_desc_str);
      if (enableDebug) console.log(displayName, "the_companies:", the_companies);
      setCompanies(the_companies);
      setLoading(false);
    });
    if(selectedCompanyId){
      if(enableDebug) console.log(displayName,"Ask all contacts for company id:",selectedCompanyId);
      const the_contacts_request: any = {
        type: "contacts_desc",
        number: 1,
        liste: [selectedCompanyId],
        from: "company_id",
        filter: ["access"],
        token: authHeader()
      }
      socket.emit("request", the_contacts_request);
      if(enableDebug) console.log(displayName,"Ask all station_id for company id:",selectedCompanyId);
      const the_station_id_request: any = {
        type: "station_id",
        number: 1,
        liste: [selectedCompanyId],
        from: "company_id",
        filter: [""],
        token: authHeader()
      }
      socket.emit("request", the_station_id_request);

    }
    if(askAllContacts){
      if(enableDebug) console.log(displayName,"Ask all SQUAMA contacts");
      const the_request: any = {
        type: "contacts_desc",
        number: 0,
        liste: [0],
        from: "company_id",
        token: authHeader()
      }
      socket.emit("request", the_request);
    }
    if(showModal){
      if (enableDebug) console.log(displayName, "Request all companies_desc");
      const the_request: any = {
        type: "companies_desc",
        number: 0,
        liste: [],
        from: "token",
        token: authHeader()
      }
      socket.emit("request", the_request);
    }else{
      reset_page();
    }
  },[showModal, selectedCompanyId, askAllContacts]);

  useEffect(() => {
    if(enableDebug) console.log(displayName,"useEffect selectedUserID:",selectedUserID);

    if(selectedUserID){
      if(contacts){
        const the_selected_contact = contacts.find(one => one.id===selectedUserID);
        if(enableDebug) console.log(displayName,"contacts:",contacts);
        if(enableDebug) console.log(displayName,"the_selected_contact:",the_selected_contact);
        if(the_selected_contact){
          setContactName(the_selected_contact.name);
          setContactEmail(the_selected_contact.email);
          setContactPhone(the_selected_contact.phone);
          setContactRole(the_selected_contact.role);
          setContactNotHashedPasswordYet("");
          setContactNotHashedConfirmedPasswordYet("");
        }
      }
    }else{
      setContactName("");
      setContactEmail("");
      setContactPhone("");
      setContactRole("user");
      setContactNotHashedPasswordYet("");
      setContactNotHashedConfirmedPasswordYet("");
    }
  },[selectedUserID]);

  useEffect(() => {
    if(enableDebug) console.log(displayName,"useEffect generatePassword:",generatePassword);
    if(generatePassword){
      const the_pass = generatePass(12);
      setContactNotHashedPasswordYet(the_pass);
      setContactNotHashedConfirmedPasswordYet(the_pass);
      setGeneratePassword(false);
    }
  },[generatePassword]);

  useEffect(() => {
    if(enableDebug) console.log(displayName,"useEffect selectedAllSquamaUserID:",selectedAllSquamaUserID);
  },[selectedAllSquamaUserID]);

  function generatePass(password_length:number):string{
    let pass = '';
    const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
        'abcdefghijklmnopqrstuvwxyz0123456789'
        +'@!#$+&$';
    for (let i = 1; i <= password_length; i++){
      let char = Math.floor(Math.random() * str.length + 1);
      pass += str.charAt(char);
    }
    return pass;
}

  const handleCloseModal = () => {setshowModal(false);}
  const handleShowModal = () => {setshowModal(true);}

  function reset_page(){
    setCompanies(null);
    setContacts(null);
    setAllStationId(null);
    setAskAllContacts(false);
    setAllSquamaContacts(null);
    setSelectedUserId(0);
    setSelectedAllSquamaUserId(0);
    setSelectedCompanyId(0);
    setContactName("");
    setContactEmail("");
    setContactPhone("");
    setContactRole("user");
    setContactNotHashedPasswordYet("");
    setContactNotHashedConfirmedPasswordYet("");
  }

  function get_access_from_role(role:string):boolean[]{
    let has_access_contract = false;
    let has_access_technical = false;
    //newContact.user_id should contain the CompanyId
    if(role === "god"){
      has_access_contract = true;
      has_access_technical = true;
    }else if(role === "admin_contract_technical"){
      has_access_contract = true;
      has_access_technical = true;
    }else if(role === "admin_contract"){
      has_access_contract = true;
    }else if(role === "admin_technical"){
      has_access_technical = true;
    }
    return [has_access_contract,has_access_technical];
  }

  function modal_create_user(){
    const onHookFormSubmit = (data: ModalItems) => {
      if(enableDebug) console.log(displayName, "data", data);
      if (!selectedUserID && !selectedAllSquamaUserID) {
        if(enableDebug) console.log(displayName, "Creating a new user");
        //Creating a new user
        if(data.contactNotHashedPasswordYet !== data.contactNotHashedConfirmedPasswordYet){
          if(enableDebug) console.log(displayName, "Password are not similar");
          window.confirm('Les mots de passe sont différents')
          return
        }
        let newContact = new Contact(0);
        if(selectedCompanyId){
          newContact.user_id = selectedCompanyId;
        }
        newContact.name = data.contactName;
        newContact.email = data.contactEmail;
        newContact.phone = data.contactPhone;
        if(data.contactRole === "god"){
          newContact.role = "god";
        }else if(data.contactRole.includes("admin")){
          newContact.role = "admin";
        }else{
          newContact.role = "user";
        }
        newContact.hashed_password = Bcrypt.hashSync(data.contactNotHashedPasswordYet);
        AuthenticationService.register(newContact).then(response => {
          if (response) {
            if(enableDebug) console.log(displayName, "Got register answer:", response);
            let access_returned = get_access_from_role(data.contactRole);
            const has_access_contract = access_returned[0];
            const has_access_technical = access_returned[1];
            //newContact.user_id should contain the CompanyId
            CompanyService.addUserToCompany(response.user_id, selectedCompanyId, has_access_contract, has_access_technical, newContact.email);
          }
        });
        //Todo add link to all station ? is this the strategy
        //CompanyService.addCompany(new Company(0, data.contactName)).then(() => handleCloseModal());
      }else if (selectedUserID && contacts && !selectedAllSquamaUserID){
        if(enableDebug) console.log(displayName, "Update the user with id:",selectedUserID)
        let the_selected_contact = contacts.find(one => one.id===selectedUserID);
        if(enableDebug) console.log(displayName, "Update the user the_selected_contact:",the_selected_contact)
        if(the_selected_contact){
          if(data.contactNotHashedPasswordYet !== data.contactNotHashedConfirmedPasswordYet){
            if(enableDebug) console.log(displayName, "Password are not similar");
            window.confirm('Les mots de passe sont différents')
            return
          }
          the_selected_contact.name = data.contactName;
          the_selected_contact.email = data.contactEmail;
          the_selected_contact.phone = data.contactPhone;
          let has_access_contract = false;
          let has_access_technical = false;
          if(data.contactRole === "god"){
            the_selected_contact.role = "god";
            has_access_contract = true;
            has_access_technical = true;
          }else if(data.contactRole === "admin_contract_technical"){
            the_selected_contact.role = "admin";
            has_access_contract = true;
            has_access_technical = true;
          }else if(data.contactRole === "admin_contract"){
            the_selected_contact.role = "admin";
            has_access_contract = true;
          }else if(data.contactRole === "admin_technical"){
            the_selected_contact.role = "admin";
            has_access_technical = true;
          }else{
            the_selected_contact.role = "user";
          }
          //Password not updated here anymore (reset link has to be used)
          //the_selected_contact.hashed_password = Bcrypt.hashSync(data.contactNotHashedPasswordYet);
          if(enableDebug) console.log(displayName, "Update the user with:");
          if(enableDebug) console.log(displayName, the_selected_contact);
          AuthenticationService.updateUser(the_selected_contact);
          //Also update alarm_warning_contact_station when the email is different
          if(selectedCompanyId){
            CompanyService.updateUserToCompany(Number(data.userIdSelected),selectedCompanyId,has_access_contract,has_access_technical,the_selected_contact.email);
          }
        }
      }else if(!selectedUserID && selectedAllSquamaUserID && allSquamaContacts){
        if(enableDebug) console.log(displayName, "Add an existing Squama user the user id:",selectedAllSquamaUserID);
        let access_returned = get_access_from_role(data.contactRole);
        const has_access_contract = access_returned[0];
        const has_access_technical = access_returned[1];
        const the_selected_all_squama_user = allSquamaContacts.find(one => one.id===selectedAllSquamaUserID);
        if(the_selected_all_squama_user){
          CompanyService.addUserToCompany(selectedAllSquamaUserID, selectedCompanyId, has_access_contract, has_access_technical, the_selected_all_squama_user.email);
        }
      }
      setshowModal(false);
      reset_page();
    };

    function delete_user(contact_id_to_delete:number, completly_delete:boolean){
      if(enableDebug) console.log(displayName, "delete_user contact_id_to_delete:",contact_id_to_delete,",allStationId:",allStationId,",selectedCompanyId:",selectedCompanyId);
      const the_selected_contact = (contacts) ? (contacts.find(one => one.id===selectedUserID)):(null);
      if(!the_selected_contact){
        console.warn(displayName, "Unable to find the contact with id:",contact_id_to_delete);
        return;
      }
      //authentication give id as user_id
      if(enableDebug) console.log(displayName, "Going to delete user.id:"+the_selected_contact.id+" email:"+the_selected_contact.email+",completly_delete:"+structuredClone(completly_delete));
      //retrieve id for email
      (allStationId) && allStationId.forEach(station_id => {
        if(enableDebug) console.log(displayName, "Remove user id:",the_selected_contact.id," from station id:",station_id);
        StationService.deleteUserToStation(the_selected_contact.id, station_id);
      });
      if(selectedCompanyId){
        if(enableDebug) console.log(displayName, "Remove user id:",the_selected_contact.id," from company id:",selectedCompanyId);
        CompanyService.deleteUserToCompany(the_selected_contact.id, selectedCompanyId);
      }
      if(completly_delete){
        if(enableDebug) console.log(displayName, "Remove from SQUAMA user email:",the_selected_contact.email);
        AuthenticationService.deleteUser(the_selected_contact.email);
      }
    }

    const onHookFormFullDelete = () => {
      if(enableDebug) console.log(displayName, "onHookFormFullDelete");
      if(window.confirm('Etes-vous sur de supprimer complètement ce Contact de SQUAMA?')){
        (selectedUserID) && delete_user(selectedUserID,true);
      }
      setshowModal(false);
      reset_page();
    };

    const onHookFormDelete = () => {
      if(enableDebug) console.log(displayName, "onHookFormDelete");
      if(window.confirm('Etes-vous sur de supprimer ce Contact de la société?')){
        (selectedUserID) && delete_user(selectedUserID,false);
      }
      setshowModal(false);
      reset_page();
    };

      return(
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            onSubmit={handleSubmit(onHookFormSubmit)}
            backdrop="static"
            centered
          >
            <Modal.Header translate="yes" closeButton>
              <Modal.Title className="squama-font">Créer/Modifier un utilisateur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {/* Select existing company */}
                <div className="row m-0">
                  <div className="col align-self-center">Nom de la société :</div>
                  <div className="col align-self-center">
                    <select className="squama-font" {...register("companyIdSelected",{ required: !selectedAllSquamaUserID })} onChange={(event)=>setSelectedCompanyId(Number(event.target.value))}>
                      <option value="0">Sélectionner</option>
                      {
                      (companies) && companies.map(company => {
                        return(<option value={company.id} key={company.id}>{company.name}</option>)
                      })
                      }
                    </select>
                  </div>
                  {(loading)?(<div className="col-1 align-self-center">{return_logo_from_type("loader","logo-sensor-half-size-png")}</div>):undefined}
                </div>
                {(usersRetrieved)?(<React.Fragment>
                  <div><hr></hr></div>
                  {/* Select new/existing station */}
                  <div className="row m-0">
                    <div className="col"/>
                    <div className="col align-self-center">
                      <select className="squama-font" {...register("userIdSelected",{ required: !selectedAllSquamaUserID })} value={selectedUserID} disabled={loading} onChange={(event)=>{
                          setSelectedUserId(Number(event.target.value));
                          setSelectedAllSquamaUserId(0);
                        }}>
                        <option value="0">NouveauContact</option>
                        {(contacts) && contacts.map(contact => {return(<option value={contact.id} key={contact.id}>{contact.email}</option>)})}
                      </select>
                    </div>
                    <div className="col" />
                  </div>
                  <div className="row m-0">
                    <div className="col-5 align-self-center squama-font">Nom* :</div>
                    <div className="col-7 align-self-center">
                      <input id="contactName" type="text" className="stay-in-div" value={contactName} {...register("contactName",{ required: !selectedAllSquamaUserID })} onChange={(e) => {setContactName(e.target.value)}}/>
                    </div>
                  </div>
                  <div className="row m-0">
                    <div className="col-5 align-self-center squama-font">Email* :</div>
                    <div className="col-7 align-self-center">
                      <input id="contactEmail" type="text" className="stay-in-div" value={contactEmail} {...register("contactEmail",{ required: !selectedAllSquamaUserID })} onChange={(e) => {setContactEmail(e.target.value)}}/>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-5 align-self-center squama-font">Phone* :</div>
                    <div className="col-7 align-self-center">
                      <input id="contactPhone" type="text" className="stay-in-div" value={contactPhone} {...register("contactPhone",{ required: !selectedAllSquamaUserID })} onChange={(e) => {setContactPhone(e.target.value)}}/>
                    </div>
                  </div>
                  <div className="row m-0">
                    <div className="col-5 align-self-center squama-font">Mot de passe* :</div>
                    <div className="col-7 align-self-center">
                      <input id="contactNotHashedPasswordYet" type={showPassword?'text':'password'} className="stay-in-div" value={contactNotHashedPasswordYet} {...register("contactNotHashedPasswordYet",{ required: (!selectedUserID && !selectedAllSquamaUserID)?(true):(false) })} onChange={(e) => {setContactNotHashedPasswordYet(e.target.value)}}/>
                    </div>
                  </div>
                  <div className="row m-0">
                    <div className="col-5 align-self-center squama-font">Re-taper Mot de passe* :</div>
                    <div className="col-7 align-self-center">
                      <input id="contactNotHashedConfirmedPasswordYet" type={showPassword?'text':'password'} className="stay-in-div" value={contactNotHashedConfirmedPasswordYet} {...register("contactNotHashedConfirmedPasswordYet",{ required: (!selectedUserID && !selectedAllSquamaUserID)?(true):(false) })} onChange={(e) => {setContactNotHashedConfirmedPasswordYet(e.target.value)}}/>
                    </div>
                  </div>
                  <div className="row m-0">
                    <div className="col-7 align-self-center squama-font">Générer le mot de passe</div>
                    <div className="col-5 align-self-center" onClick={()=>setGeneratePassword(true)}>
                      {return_logo_from_type("setup","logo-sensor-half-size-png")}
                    </div>
                  </div>
                {(selectedUserID) ? (
                  <div className="col align-self-center">
                    <button type="button" className="squama-btn-navbar squama-btn-gestion-delete squama-btn-gestion-modal-bigger" onClick={onHookFormDelete}>
                      Supprimer de cette société
                    </button>
                    <button type="button" className="squama-btn-navbar squama-btn-gestion-delete squama-btn-gestion-modal-bigger" onClick={onHookFormFullDelete}>
                      Supprimer de tout SQUAMA
                    </button>
                  </div>
                ):undefined}
                  <div><hr></hr></div>
                  <div className="row m-0">
                    <div className="col-7 align-self-center squama-font">Un autre utilisateur SQUAMA ?</div>
                    <div className="col-5 align-self-center">
                      <input id="ask-all-contacts" type="checkbox" className="filled-in" defaultChecked={askAllContacts} onChange={()=>setAskAllContacts(!askAllContacts)}/>
                    </div>
                  </div>
                  {
                    (allSquamaContacts)&&(
                      <select className="squama-font" {...register("allSquamaUserIdSelected",{ required: false })} value={selectedAllSquamaUserID} onChange={(event)=>{
                        setSelectedAllSquamaUserId(Number(event.target.value));
                        setSelectedUserId(0);
                      }}>
                        <option value="0">ContactExistant</option>
                        {allSquamaContacts.map(contact => {return(<option value={contact.id} key={contact.id}>{contact.name+"("+contact.email+")"}</option>)})}
                      </select>
                    )
                  }
                  <div><hr></hr></div>
                  <div className="row m-0">
                    <div className="col-5 align-self-center squama-font">Role* :</div>
                    <div className="col-7 align-self-center">
                      <div className="squama-font"><input type="radio" id="god" value="god" checked={contactRole ==="god"} {...register("contactRole",{ required: !selectedAllSquamaUserID })} onChange={(e) => {setContactRole("god")}}/>Dieu</div>
                      <div className="squama-font"><input type="radio" id="admin_contract_technical" value="admin_contract_technical" checked={contactRole ==="admin_contract_technical"} {...register("contactRole",{ required: !selectedAllSquamaUserID })} onChange={(e) => {setContactRole("admin_contract_technical")}}/>Admin du contrat et technique</div>
                      <div className="squama-font"><input type="radio" id="admin_contract" value="admin_contract" checked={contactRole ==="admin_contract"} {...register("contactRole",{ required: !selectedAllSquamaUserID })} onChange={(e) => {setContactRole("admin_contract")}}/>Admin du contrat</div>
                      <div className="squama-font"><input type="radio" id="admin_technical" value="admin_technical" checked={contactRole ==="admin_technical"} {...register("contactRole",{ required: !selectedAllSquamaUserID })} onChange={(e) => {setContactRole("admin_technical")}}/>Admin technique</div>
                      <div className="squama-font"><input type="radio" id="user" value="user" checked={contactRole ==="user"} {...register("contactRole",{ required: !selectedAllSquamaUserID })} onChange={(e) => {setContactRole("user")}}/>Utilisateur</div>
                    </div>
                  </div>
                </React.Fragment>
                ):undefined}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={handleCloseModal}>
                Annuler
              </button>
              <button type="submit" className="squama-btn-navbar squama-btn-gestion squama-btn-gestion-modal" onClick={handleSubmit(onHookFormSubmit)}>
                Enregistrer
              </button>
            </Modal.Footer>
          </Modal>
      )
    }
    return (
        <div className="squama-item-navbar">
          {modal_create_user()}
          <button type="button" className="squama-btn-navbar squama-btn-gestion" onClick={handleShowModal}>
          Créer/Modifier un utilisateur
          </button>
        </div>
    )
}

export default ModalCreateModifyUser;