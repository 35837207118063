import { FunctionComponent } from 'react';
import Station from '../models/station';
import WaterLevelSensor from '../models/water-level-sensor';
import AnalogSensor from '../models/analog-sensor';
import {return_color_for_water_level_from_bool,
        return_unity_from_type,
        get_all_analog_sensors_from_station,
        get_all_water_level_sensors_from_station,
        return_logo_from_type,
        translate_water_level_type,
        is_one_waterlevel_is_dirty,
        station_get_pkd_fault} from '../helpers/station-helper';
import {format_date} from '../helpers/format-date';
import ReactTooltip from "react-tooltip";
import AuthenticationService from '../services/authentication-service';

type Props = {
  station: Station,
  fullscreen: boolean
};

const StationCardDash: FunctionComponent<Props> = ({station, fullscreen}) => {
  const displayName = "StationCardDash:";
  const local_debug = false;
  const local_very_debug = false;

  local_very_debug && console.log(displayName, "station:", station);

  let water_level_sensors = get_all_water_level_sensors_from_station(station);
  let analog_sensors = get_all_analog_sensors_from_station(station);
  const current_user_is_god = AuthenticationService.getUserIsGod();

  function display_water_level_sensors(all_sensors: Array<WaterLevelSensor> | undefined, type: string) {
    if(all_sensors === undefined){return}
    if(all_sensors.length <= 0){return}
    let sensors = all_sensors.filter(sensor => (sensor.type === type))
    if (type!=="general"){
      sensors = sensors.sort((a, b) => a.depth < b.depth ? 1 : -1)
    }
    if(sensors === undefined){return}
    if(sensors.length <= 0){return}

    function display_days_text(last_update: string){
      if(last_update !== "-"){
        let returned = last_update+" jour"
        if(Number(last_update)>1){
          returned += "s"
        }
        return returned;
      }
      return "- jour";
    }

    function display_general_status(all_sensors: Array<WaterLevelSensor> | undefined){
      let color_returned: string = "green";
      if((all_sensors)&&(all_sensors.length)){
        all_sensors.forEach(sensor => {
          if((sensor.general_status==="red") && (color_returned === "orange")){
            color_returned = sensor.general_status;
          }
          if((sensor.general_status==="orange") && (color_returned === "green")){
            color_returned = sensor.general_status;
          }
          if((sensor.general_status==="red") && (color_returned === "green")){
            color_returned = sensor.general_status;
          }
        })
      }
      if(color_returned === "green"){
        return (<div/>)
      }else{
        return (
          <div className={color_returned+"dashled dash-logo-led"} />
        );
      }
    }

    return (
      <div className="panel-station-dashboard-sensor-main">
        <div className="panel-station-dashboard-sensor-title-part">
          {(type!=="general")&&(
            <div className="panel-station-dashboard-sensor-title-top text-dashboard-font">
              {"Niveau "+translate_water_level_type(type)}
            </div>
          )
          }
          <div className="panel-station-dashboard-sensor-title-bottom" data-tip={(current_user_is_god)&&(is_one_waterlevel_is_dirty(all_sensors))?"Capteur sale<br/>Nettoyage nécessaire":""}>
            {display_general_status(sensors)}
            {(current_user_is_god)&&(is_one_waterlevel_is_dirty(all_sensors))?return_logo_from_type("water_level_dirty", "logo-sensor-png"):return_logo_from_type("water_level", "logo-sensor-png")}
          </div>
        </div>
        <div className="panel-station-dashboard-sensor-content-part" >
          {sensors && sensors.map(sensor => (
            <div key={"water_level-"+sensor.id} data-tip={format_date(sensor.date_current_value)} className="panel-station-dashboard-sensor-content-part-line">
              <div className="panel-station-dashboard-sensor-waterlevel-content-part-name-depth">
                {(type === "general")?(sensor.name):(sensor.depth+" cm")}
              </div>
              <div className="panel-station-dashboard-sensor-waterlevel-content-part-live">
                <div className={ return_color_for_water_level_from_bool(sensor.current_value) + "led led-sensor"}/>
              </div>
              <div className="panel-station-dashboard-sensor-waterlevel-content-part-days">
                {display_days_text(sensor.last_update)}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  function display_analog_sensors_in_table_depending_on_type(all_sensors: Array<AnalogSensor>|undefined, type: string) {
    if(all_sensors === undefined){return}
    if(all_sensors.length <= 0){return}
    let sensors = all_sensors.filter(sensor => (sensor.type === type))
    if(sensors === undefined){return}
    if(sensors.length <= 0){return}
    const pkd_fault = station_get_pkd_fault(station);

    function display_general_status(all_sensors: Array<AnalogSensor> | undefined){
      let color_returned: string = "green";
      if((all_sensors)&&(all_sensors.length)){
        all_sensors.forEach(sensor => {
          if(sensor.general_status){
            if((sensor.general_status==="red") && (color_returned === "orange")){
              color_returned = sensor.general_status;
            }
            if((sensor.general_status==="orange") && (color_returned === "green")){
              color_returned = sensor.general_status;
            }
            if((sensor.general_status==="red") && (color_returned === "green")){
              color_returned = sensor.general_status;
            }
          }
        })
      }
      if(color_returned === "green"){
        return (<div/>)
      }else{
        return (
          <div className={color_returned+"dashled dash-logo-led"} />
        );
      }
    }

    function displayMinMax(min:number, max:number){
      //console.log("min:"+min);
      //console.log("max:"+max);
      if((min != null) && (max != null)){
        return min+return_unity_from_type(type)+" - "+max+return_unity_from_type(type)
      }
      return return_unity_from_type(type)+" - "+return_unity_from_type(type)
    }

    return (
    <div className="panel-station-dashboard-sensor-main-container">
      <div className="panel-station-dashboard-sensor-main">
        <div className="panel-station-dashboard-sensor-title-part">
          <div data-tip={(pkd_fault)?"Depuis "+format_date(pkd_fault.date_started):""} className="panel-station-dashboard-sensor-title-top">
            {display_general_status(sensors)}
            {(pkd_fault)?(return_logo_from_type("pkd_alarm", "logo-sensor-png")):(return_logo_from_type(type, "logo-sensor-png"))}
          </div>
        </div>
        <div className="panel-station-dashboard-sensor-content-part" >
          {sensors && sensors.map(sensor => (
            <div key={"analog-"+sensor.id} className="panel-station-dashboard-sensor-content-part-line">
              <div className="panel-station-dashboard-sensor-analog-content-part-name">
                {sensor.name}
              </div>
              <div className="panel-station-dashboard-sensor-analog-content-part-live">
                <div data-tip={format_date(sensor.date_current_value)} className="panel-station-dashboard-sensor-analog-content-part-live-current text-dashboard-live-sensor-font">
                  {sensor.current_value}{return_unity_from_type(type)}
                </div>
                <div data-tip="min-max /24h" className="panel-station-dashboard-sensor-analog-content-part-minmax text-dashboard-minmax-sensor-font">
                  {(sensor.min) && (sensor.max) && displayMinMax(sensor.min, sensor.max)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    )
  }

  return (
    <div>
      <div className="station-card-body-table">
        {display_water_level_sensors(water_level_sensors, "upstream")}
        {display_water_level_sensors(water_level_sensors, "downstream")}
        {display_water_level_sensors(water_level_sensors, "general")}
        {display_analog_sensors_in_table_depending_on_type(analog_sensors, "temperature")}
        {display_analog_sensors_in_table_depending_on_type(analog_sensors, "oxymeter")}
        {display_analog_sensors_in_table_depending_on_type(analog_sensors, "turbidity")}
      </div>
        {(station.migration_in_progress) && (
          <div className="panel-station-migration_in_progress-status text-warning-font">
            Période de migration en cours
          </div>)}
      <ReactTooltip type="light" />
    </div>
  )
}

export default StationCardDash;
