import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Group } from '@visx/group';
import { scaleTime, scaleLinear } from '@visx/scale';
import { LinePath, Line, Bar } from '@visx/shape';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { GridRows, GridColumns } from '@visx/grid';
import { Brush } from '@visx/brush';
import { Threshold } from '@visx/threshold';
import { Bounds } from '@visx/brush/lib/types';
import { PatternLines } from '@visx/pattern';
import { extent, bisector } from 'd3-array';
import { curveMonotoneX } from '@visx/curve';
import CharAnalogValue from '../models/chart-analog-value';
import CharAnalogValues from '../models/chart-analog-values';
import { localPoint } from '@visx/event';
import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { timeFormat, timeFormatDefaultLocale } from 'd3-time-format';

type TooltipData = CharAnalogValue;

timeFormatDefaultLocale({
  dateTime    : '%a %b %e %X %Y',
  date        : '%d/%m/%Y',
  time        : '%H%H:%m%m:%s%s',
  periods     : ['AM', 'PM'],
  days        : ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  shortDays   : ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
  months      : ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
  shortMonths : ['Janv', 'Fev', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec']
});

const hideMainBottomAxis=false;
const hideMainLeftAxis=false;
const hideZoomBottomAxis=false;
const hideZoomLeftAxis=false;

// Initialize some variables
const brushMargin = { top: 0, bottom: 10, left: 40, right: 20 };
/* space between real chart and zoom zone */
const chartSeparation = 20;
const PATTERN_ID = 'brush_pattern';
export const accentColor = '#607D8B';//color inside the selected grille
export const background = '#FFFFFF';//main background mix 1
export const tooltipColor = '#004a55';
const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: 'black',
};

const formatDate = timeFormat("Le %d/%m/%y à %H:%M");

// accessors
const getDate = (d: CharAnalogValue) => new Date(d.date.replace(" ","T"));
const bisectDate = bisector<CharAnalogValue, Date>(d => new Date(d.date.replace(" ","T"))).left;
const getTheValue = (d: CharAnalogValue, valueRef: number) => d.value[valueRef];
const getTheMinValue = (d: CharAnalogValue) => d.minValue;
const getTheMaxValue = (d: CharAnalogValue) => d.maxValue;

export type BrushProps = {
  allValues: CharAnalogValues[];
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  compact?: boolean;
};

export default withTooltip<BrushProps, TooltipData>(
  ({
        compact = false,
        allValues,
        width,
        height,
        margin = {
          top: 20,
          left: 40,
          bottom: 20,
          right: 20,
        },
        showTooltip,
        hideTooltip,
        tooltipData,
        tooltipTop = 0,
        tooltipLeft = 0,
      }: BrushProps & WithTooltipProvidedProps<TooltipData>) => {
  const [filteredValues, setfilteredValues] = useState<CharAnalogValue[]>(allValues[0].values_and_date_of_same_scale_and_unity);
  const [enableMinMax, setEnableMinMax] = useState<boolean>(false);

  useEffect(() => {
    setfilteredValues(allValues[0].values_and_date_of_same_scale_and_unity);
    setEnableMinMax(allValues[0].enable_min_max);
    //console.log("allValues.length:",allValues.length);
    //console.log("allValues[0]:",allValues[0]);
  },[allValues]);

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return;
    const { x0, x1, y0, y1 } = domain;
    const stockCopy = allValues[0].values_and_date_of_same_scale_and_unity.filter(s => {
      const x = getDate(s).getTime();
      const y = getTheValue(s,0);
      return x > x0 && x < x1 && y > y0 && y < y1;
    });
    setfilteredValues(stockCopy);
  };

  const innerHeight = height - margin.top - margin.bottom;
  const topChartBottomMargin = compact ? chartSeparation / 2 : chartSeparation + 10;
  const topChartHeight = 0.8 * innerHeight - topChartBottomMargin;
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation;

  // bounds
  const xAmplitude = Math.max(width - margin.left - margin.right, 0);
  const yMax = Math.max(topChartHeight, 0);
  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0);
  const yBrushMax = Math.max(bottomChartHeight - brushMargin.top - brushMargin.bottom, 0);

  function getXMinAndMaxValueScale(arrayUsed:Array<CharAnalogValue>, enable_min_max:boolean) {
    if((arrayUsed.length <=0)||(arrayUsed[0].value.length<=0)){
      return [0,0];
    }
    let xmin = arrayUsed[0].value[0];
    let xmax = xmin;
    for(var one_values_date=0; one_values_date < arrayUsed.length;one_values_date++){
        if(enable_min_max){
          xmin = Math.min(arrayUsed[one_values_date].minValue, xmin);
          xmax = Math.max(arrayUsed[one_values_date].maxValue, xmax);
        }else{
          xmin = Math.min(...arrayUsed[one_values_date].value, xmin);
          xmax = Math.max(...arrayUsed[one_values_date].value, xmax);
        }
      }
    if(allValues[0].alarm_level_low !== undefined){
      xmin = Math.min(allValues[0].alarm_level_low, xmin);
    }
    if(allValues[0].alarm_level_high !== undefined){
      xmax = Math.max(allValues[0].alarm_level_high, xmax);
    }
    if(allValues[0].warning_level_low !== undefined){
      xmin = Math.min(allValues[0].warning_level_low, xmin);
    }
    if(allValues[0].warning_level_high !== undefined){
      xmax = Math.max(allValues[0].warning_level_high, xmax);
    }
    return [xmin,xmax];
  }

  function getTooltipValues (d: CharAnalogValue, the_unity:string, enable_min_max:boolean) {
    if(enable_min_max){
      return d.value.map(one=>one.toFixed(2)).join(";")+the_unity+"<"+d.minValue+the_unity+";"+d.maxValue+the_unity+">";
    }
    return d.value.map(one=>one.toFixed(2)).join(";")+the_unity;
  }

  // scales
  const dateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xAmplitude],
        domain: extent(filteredValues, getDate) as [Date, Date],
      }),
    [xAmplitude, filteredValues],
  );
  const analogValueScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: getXMinAndMaxValueScale(filteredValues, enableMinMax),
        nice: true,
      }),
    [yMax, filteredValues],
  );
  const brushDateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: extent(allValues[0].values_and_date_of_same_scale_and_unity, getDate) as [Date, Date],
      }),
    [xBrushMax, allValues],
  );
  const brushAnalogValueScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: getXMinAndMaxValueScale(allValues[0].values_and_date_of_same_scale_and_unity,enableMinMax),
        //domain: [min(allValues[0].values_and_date_of_same_scale_and_unity, getTheValueOfFirstValue) || 0, max(allValues[0].values_and_date_of_same_scale_and_unity, getTheValueOfFirstValue) || 0],
        nice: true,
      }),
    [yBrushMax, allValues],
  );

  const initialBrushPosition = useMemo(
    () => ({
      start: { x: brushDateScale(getDate(allValues[0].values_and_date_of_same_scale_and_unity[Math.floor(allValues[0].values_and_date_of_same_scale_and_unity.length*(2/3))])) },
      end: { x: brushDateScale(getDate(allValues[0].values_and_date_of_same_scale_and_unity[allValues[0].values_and_date_of_same_scale_and_unity.length-1])) },
    }),
    [brushDateScale, allValues],
  );

  const x_tooltip_delta = margin.left;

  const handleMouseMove = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      //console.log("useCallback called");
      const { x } = localPoint(event) || { x: 0 };

      const x0 = dateScale.invert(x - x_tooltip_delta);
      const index = bisectDate(filteredValues, x0, 1);
      const d0 = filteredValues[index - 1];
      const d1 = filteredValues[index];
      let d = d0;
      if (d1 && getDate(d1)) {
        d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
      }
      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: analogValueScale(getTheValue(d,0)),
      });
    },
    [showTooltip, analogValueScale, dateScale, filteredValues, x_tooltip_delta],
  );

  //console.log("some info:");
  //console.log("min:"+min(allValues, getTheMinValue));
  //console.log("max:"+max(allValues, getTheMaxValue));
  //console.log("minFiltered:"+min(filteredValues, getTheMinValue));
  //console.log("maxFiltered:"+max(filteredValues, getTheMaxValue));
  //console.log("maxFiltered:"+max(filteredValues, getTheMaxValue));
  //console.log("brushDateScale:"+extent(allValues, getDate));
  //console.log("dateScale:"+extent(filteredValues, getDate));
  //console.log(tooltipData);
  //tooltipData && console.log("tooltipData:"+getTheValue(tooltipData));

  let y_unity = allValues[0].unity;
  let enable_min_max = allValues[0].enable_min_max;
  let enable_tooltip_point = allValues[0].enable_tooltip_point;


  // Initialize some variables
  const axisColor = "#000";
  const axisBottomTickLabelProps = {
    textAnchor: 'middle' as const,
    fontFamily: 'Arial',
    fontSize: 10,
    fill: axisColor,
  };

  const axisLeftTickLabelProps = {
    dx: '-0.25em',
    dy: '0.25em',
    fontFamily: 'Arial',
    fontSize: 10,
    textAnchor: 'end' as const,
    fill: axisColor,
  };

  const displayLineValues = (valuesRef:number,one_color:string) => {
    return(
      <LinePath<CharAnalogValue>
        key={"line-"+valuesRef}
        id={`${Math.random()}`}
        data={filteredValues}
        x={d => dateScale(getDate(d)) || 0}
        y={d => analogValueScale(getTheValue(d,valuesRef)) || 0}
        stroke={one_color}
        strokeWidth={1}
        strokeOpacity={1}
        shapeRendering="geometricPrecision"
        markerMid="url(#marker-circle)"
        markerStart="url(#marker-line)"
        markerEnd="url(#marker-arrow)"
      />
    );
  }

  const displayZoomLineValues = (valuesRef:number, one_color:string) => {
    return (
      <LinePath<CharAnalogValue>
        key={"zoom-"+valuesRef}
        id={`${Math.random()}`}
        data={allValues[0].values_and_date_of_same_scale_and_unity}
        x={d => brushDateScale(getDate(d)) || 0}
        y={d => brushAnalogValueScale(getTheValue(d,valuesRef)) || 0}
        stroke={one_color}
        strokeWidth={1}
        strokeOpacity={1}
        shapeRendering="geometricPrecision"
        markerMid="url(#marker-circle)"
        markerStart="url(#marker-line)"
        markerEnd="url(#marker-arrow)"
      />
    )
  }
  const display_over_part = (allValues[0].alarm_level_high !== undefined) && (analogValueScale(allValues[0].alarm_level_high) < yMax)
  const display_under_part = (allValues[0].alarm_level_low !== undefined) && (analogValueScale(allValues[0].alarm_level_low) > 0)
  const display_part = (allValues[0].alarm_level_high !== undefined) && (allValues[0].alarm_level_low !== undefined) && (allValues[0].warning_level_high !== undefined) && (allValues[0].warning_level_low !== undefined)
  const display_grid_rows = (allValues[0].grid_rows !== undefined)?(allValues[0].grid_rows):(false)
  const display_grid_columns = (allValues[0].grid_columns !== undefined)?(allValues[0].grid_columns):(false)
  return (
    <div>
      <svg width={width} height={height}>
        {/* Main chart part */}
        <Group  left={margin.left} top={topChartBottomMargin}>
          {(display_grid_rows)&&(<GridRows scale={analogValueScale} numTicks={5} width={xAmplitude} height={yMax} stroke="#e0e0e0" />)}
          {(display_grid_columns)&&(<GridColumns scale={dateScale}numTicks={width > 520 ? 10 : 5} width={xAmplitude} height={yMax} stroke="#e0e0e0" />)}
          {(display_over_part)&&(
            <rect fillOpacity={0.2} x={0} width={xAmplitude}
              y={0} fill="red"
              height={analogValueScale(allValues[0].alarm_level_high)}/>
          )}
          {(display_part)&&(
            <rect fillOpacity={0.2} x={0} width={xAmplitude}
              y={analogValueScale(allValues[0].alarm_level_high)} fill="orange"
              height={analogValueScale(allValues[0].warning_level_high)-analogValueScale(allValues[0].alarm_level_high)}/>
          )}
          {(display_part)&&(
            <rect fillOpacity={0.2} x={0} width={xAmplitude} fill="#9BC1BE"
              y={analogValueScale(allValues[0].warning_level_high)}
              height={analogValueScale(allValues[0].warning_level_low)-analogValueScale(allValues[0].warning_level_high)}/>
          )}
          {(display_part)&&(
            <rect fillOpacity={0.2} x={0} width={xAmplitude} fill="#5787b7"
            y={analogValueScale(allValues[0].warning_level_low)}
            height={analogValueScale(allValues[0].alarm_level_low)-analogValueScale(allValues[0].warning_level_low)}/>
          )}
          {(display_under_part)&&(
            <rect fillOpacity={0.2} x={0} width={xAmplitude}
              y={analogValueScale(allValues[0].alarm_level_low)} fill="#2290ff"
              height={yMax-analogValueScale(allValues[0].alarm_level_low)}/>
          )}
            {(y_unity !== "")&&(
              <text x="-5" y="10" transform="rotate(-90)" fontSize={10} fill={"black"}>
                {y_unity}
              </text>)}
            {(enable_min_max) && (<Threshold<CharAnalogValue>
                id={`${Math.random()}`}
                data={filteredValues}
                x={d => dateScale(getDate(d)) || 0}
                y0={d => analogValueScale(getTheMinValue(d)) || 0}
                y1={d => analogValueScale(getTheMaxValue(d)) || 0}
                clipAboveTo={0}
                clipBelowTo={yMax}
                curve={curveMonotoneX}
                belowAreaProps={{
                fill: '#607D8B',
                fillOpacity: 0.4,
                }}
                aboveAreaProps={{
                fill: '#607D8B',
                fillOpacity: 0.4,
                }}
            />)}
            {allValues[0].values_and_date_of_same_scale_and_unity[0].value.map((one,i)=> displayLineValues(i,allValues[0].values_color[i]))}
            <Bar
              x={0}
              y={0}
              width={xAmplitude}
              height={yMax}
              fill="transparent"
              rx={0}
              onMouseMove={handleMouseMove}
              onMouseLeave={() => hideTooltip()}
              onTouchStart={() => hideTooltip()}
              onTouchMove={handleMouseMove}
            />
            {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft - x_tooltip_delta, y: margin.top }}
                to={{ x: tooltipLeft - x_tooltip_delta, y: innerHeight + margin.top }}
                stroke={tooltipColor}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              {enable_tooltip_point && (
              <Group>
                <circle
                  cx={tooltipLeft - x_tooltip_delta}
                  cy={tooltipTop + 1}
                  r={4}
                  fill="black"
                  fillOpacity={0.1}
                  stroke="black"
                  strokeOpacity={0.1}
                  strokeWidth={2}
                  pointerEvents="none"
                />
                <circle
                  cx={tooltipLeft - x_tooltip_delta}
                  cy={tooltipTop}
                  r={4}
                  fill={tooltipColor}
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </Group>
              )}
            </g>
          )}
          {!hideMainBottomAxis && (
            <AxisBottom
              top={yMax}
              scale={dateScale}
              numTicks={width > 520 ? 10 : 5}
              stroke={axisColor}
              tickStroke={axisColor}
              tickLabelProps={() => axisBottomTickLabelProps}
            />
          )}
          {!hideMainLeftAxis && (
            <AxisLeft
              scale={analogValueScale}
              numTicks={5}
              stroke={axisColor}
              tickStroke={axisColor}
              tickLabelProps={() => axisLeftTickLabelProps}
            />
          )}
        </Group>
        {/* Zoom */}
          <Group left={margin.left} top={topChartHeight + topChartBottomMargin + margin.top}>
            {allValues[0].values_and_date_of_same_scale_and_unity[0].value.map((one,i)=> displayZoomLineValues(i,allValues[0].values_color[i]))}
            {!hideZoomBottomAxis && (
              <AxisBottom
                top={yBrushMax}
                scale={brushDateScale}
                numTicks={width > 520 ? 10 : 5}
                stroke={axisColor}
                tickStroke={axisColor}
                tickLabelProps={() => axisBottomTickLabelProps}
              />
            )}
            {!hideZoomLeftAxis && (
              <AxisLeft
                scale={brushDateScale}
                numTicks={5}
                stroke={axisColor}
                tickStroke={axisColor}
                tickLabelProps={() => axisLeftTickLabelProps}
              />
            )}
            <PatternLines
              id={PATTERN_ID}
              height={8}
              width={8}
              stroke={accentColor}
              strokeWidth={1}
              orientation={['diagonal']}
            />
            <Brush
              xScale={brushDateScale}
              yScale={brushAnalogValueScale}
              width={xBrushMax}
              height={yBrushMax}
              margin={brushMargin}
              handleSize={8}
              resizeTriggerAreas={['left', 'right']}
              brushDirection="horizontal"
              initialBrushPosition={initialBrushPosition}
              onChange={onBrushChange}
              onClick={() => setfilteredValues(allValues[0].values_and_date_of_same_scale_and_unity)}
              selectedBoxStyle={selectedBrushStyle}
            />
          </Group>
          {/* end of zoom part */}
      </svg>
      {tooltipData && (
        <div>
            <Tooltip
              top={0 }
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 160,
                textAlign: 'center',
                transform: 'translateX(-50%)',
              }}
            >
              {formatDate(getDate(tooltipData))+" : "+getTooltipValues(tooltipData, y_unity, enableMinMax)}
            </Tooltip>
          </div>
      )}
    </div>
  );
})